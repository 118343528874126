.login-form {
    padding: 20px;
    margin: 0 auto;
    max-width: 400px;
}

.form-group {
    margin-bottom: 20px;
}

.form-label {
    display: block;
    font-size: 16px;
    color: #333;
    margin-bottom: 8px;
}

.form-input {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 16px;
    padding: 0 8px;
}

.login-button {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
}