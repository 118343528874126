.task-list {
    background: #e8e8e8;
    border-radius: 4px;
    max-width: 400px;
    padding: 5px;
}

.task {
    align-items: center;
    background: #fff;
    border-radius: 5px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
    display: flex;
    font-size: 20px;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 50px 100px;
}
.container {
    margin: 1em;
    min-width: 20em;
    max-width: 40em;
}

.task-meta {
    color: grey;
    /* font-size: small; */
    margin-top: -10px;
    margin-bottom: 0.5em;
}

.task-meta img {
    margin-right: 5px;
}

form {
    text-align: left;
    margin: 10px 0px 10px 0px;
}

label {
    font-weight: 700;
    color: rgb(92, 92, 92);
}
input {
    margin-bottom: 30px;
}

.card {
    margin: 15px 5px 15px 0px;
}
