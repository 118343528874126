* {
    box-sizing: border-box;
}

body {
    font-family: sans-serif;
    margin: 3rem;
    background-color: #fff;
}

h1,
h2 {
    color: #333333;
}

.actions {
    text-align: right;
}
/*
.modal {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background-color: white;
  padding: 1rem;
  text-align: center;
  width: 30rem;
  z-index: 10;
  position: fixed;
  top: 20vh;
  left: calc(50% - 15rem);
}

.backdrop {
  position: fixed;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0; */
/* } */

.datatables-overflow {
    overflow-y: visible !important;
}